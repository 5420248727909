import { useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
import http from './http'

const Guard = () => {
  const navigate = useNavigate()

  useEffect( () => {
    const token = localStorage.getItem( 'token' )

    if ( ! token )
      return navigate( '/auth/login' )

    http
      .post( '/api/auth/ping' )
      .then( ( { data } ) => {
        const { _id, username } = data
        localStorage.setItem( 'userId', _id )
        localStorage.setItem( 'username', username )
      } )
  } )

  return <Outlet />
}

export default Guard
