import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'

import Guard from './Guard'
import App from './App'
import { Login } from './routes/auth'

const Blank = () => {

  return <Outlet />
}

const Router = () => {

  return <BrowserRouter>
    <Routes>
      <Route path="/" Component={ Guard }>
        <Route
          index
          Component={ App }
        />
      </Route>

      <Route path="auth" Component={ Blank }>
        <Route path="login" Component={ Login } />
      </Route>
    </Routes>
  </BrowserRouter>
}

export default Router
