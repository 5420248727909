import Fetch from '../components/Fetch'

import { LuClipboardList } from 'react-icons/lu'
import { TbCheckupList } from 'react-icons/tb'
import { FiAlertTriangle, FiTrash } from 'react-icons/fi'

const statusList = [
  {
    endpoint: '/api/product/count',
    label: 'catalog',
    icon: {
      i: TbCheckupList,
      color: 'color-green'
    }
  },
  {
    endpoint: '/api/product/count',
    label: 'violations',
    icon: {
      i: FiAlertTriangle,
      color: 'color-yellow'
    },
    params: {
      exists: true
    }
  },
  {
    endpoint: '/api/product/aggregate',
    method: 'POST',
    label: 'deleted',
    icon: {
      i: FiTrash,
      color: 'color-red'
    },
    params: {
      __first: true,
      exists: true
    },
    body: [
			{
				"$match": {
					"exists": true
				}
			},
			{
				"$project": {
					"identity": 1
				}
			},
			{
				"$lookup": {
					"from": "links",
					"let": {
						"id": "$identity"
					},
					"pipeline": [
						{
							"$match": {
								"$expr": {
									"$eq": [
										"$identity",
										"$$id"
									]
								}
							}
						},
						{
							"$project": {
								"status": 1
							}
						},
						{
							"$group": {
								"_id": null,
								"total": {
									"$sum": 1
								},
								"deleted": {
									"$sum": {
										"$cond": {
											"if": {
												"$eq": [
													"$status",
													"deleted"
												]
											},
											"then": 1,
											"else": 0
										}
									}
								}
							}
						},
						{
							"$match": {
								"$expr": {
									"$eq": [
										"$total",
										"$deleted"
									]
								}
							}
						}
					],
					"as": "links"
				}
			},
			{
				"$unwind": "$links"
			},
			{
				"$group": {
					"_id": null,
					"count": {
						"$sum": 1
					}
				}
			}
		]
  }
]

const Products = () => {

  return <section className="hs gap g3">
    { /* PRODUCTS */ }
    <div className="vs gap align-center">
      <div className="f3-8">
        <LuClipboardList />
      </div>

      <h1 className="f2-8">
        products
      </h1>
    </div>

    <div className="vs wrap gap g3">
      { statusList.map( ( item, index ) => <Fetch
        key={ index }
        { ...item }
      >
        { ( { count } = { count: 0 } ) => {
          const { icon: { i: Icon, color } } = item

          return <div className="one vs gap border radius shadow padding p3">
            <div className={ `f6 ${ color }` }>
              <Icon />
            </div>

            <div>
              <h1 className="f5">
                { count }
              </h1>

              <h3 className="f2-1">
                { item.label }
              </h3>
            </div>
          </div>
        } }
      </Fetch> ) }

    </div>
    { /* END PRODUCTS */ }
  </section>
}

export default Products
