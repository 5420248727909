import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import http from '../../http'
import logo from '../../logo.svg'

import { FiUser, FiLock, FiEye, FiEyeOff } from 'react-icons/fi'

const Login = () => {
  const navigate = useNavigate()
  const [ showPass, setShowPass ] = useState( false )

  const [ model, updateModel ] = useState( {
    username: '',
    password: ''
  } )

  const handleShowPass = () => {
    setShowPass( ! showPass )
  }

  const handleInputChange = ( e ) => {
    const { name, value } = e.target
    updateModel( { ...model, [ name ]: value } )
  }

  const login = ( e ) => {
    e.preventDefault()

    http
      .post( '/api/auth/login', model )
      .then( ( { data } ) => {
        const { token, profile } = data
        localStorage.setItem( 'token', token )
        localStorage.setItem( 'profile', JSON.stringify( profile  ) )
        navigate( '/' )
      } )

    console.log( 'login', model )
  }

  return <main className="one hs align-center justify-center">
    <div className="w49 hs padding p4 gap g4 bg-white border radius">
      <div className="hs align-center">
        <img src={ logo } alt="logo" className="w10" />
      </div>

      <form onSubmit={ login } className="hs gap g2">
        <div className="vs h7 bg-white border bc-gray radius">
          <div className="w7 h7 hs justify-center align-center f3">
            <FiUser />
          </div>

          <input
            name="username"
            value={ model.username }
            onChange={ handleInputChange }
            placeholder="username"
            size={ 1 }
            required
            className="one bg-none f3"
          />
        </div>

        <div className="vs h7 bg-white border bc-gray radius">
          <div className="w7 h7 hs justify-center align-center f3">
            { model.password !== '' ? showPass ? <FiEyeOff onClick={ handleShowPass } /> : <FiEye onClick={ handleShowPass } /> : <FiLock /> }
          </div>

          <input
            name="password"
            type={ showPass ? 'text' : 'password' }
            value={ model.password }
            onChange={ handleInputChange }
            placeholder="password"
            size={ 1 }
            required
            className="one bg-none f3"
          />
        </div>

        <div className="vs flex-end">
          <button className="h6 f3 padding p2 p-sides border radius bg-primary color-white">
            enter
          </button>
        </div>
      </form>
    </div>
  </main>
}

export default Login
