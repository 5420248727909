import React, { useState, useEffect } from 'react'
import CustomLabel from './_CustomLabel'
import CustomActive from './_CustomActive'

import {
  PieChart as Chart,
  Pie as RPie,
  Cell,
  LabelList,
} from 'recharts'

const Empty = () => {
  return <>empty</>
}

const colorList = [
  '#000000',
  '#fdaf78',
  '#d0c0f5',
  '#c2ffed',
  '#ec6a82',
  '#d59200',
  '#9b2168',
  '#77c32d',
  '#f6776b',
  '#6c85fa',
  '#ae6289',
  '#c9c2d7',
  '#84614f',
  '#4d89c1'
]

function Pie( props ) {
  const {
    data,
    labelKey = 'key',
    valueKey = 'count',
    width = 400,
    height = 300
  } = props

  const [ state, setState ] = useState( {
    data: [],
    total: 0,
    length: 0,
    index: 0
  } )

  useEffect( () => {
    const index = Math.floor( Math.random() * colorList.length )
    const colors = [ ...colorList.slice( index ), ...colorList.slice( 0, index ) ]

    const transformedData = data
      .map( ( el, i ) => ( {
        label: el[ labelKey ] || '',
        value: el[ valueKey ],
        color: colors[ i ]
      } ) )

    const total = transformedData
      .reduce( ( acc, curr ) => ( acc + curr.value ), 0 )

    setState( {
      data: transformedData,
      total,
      length: data.length
    } )

  }, [ data, labelKey, valueKey ] )

  function onPieEnter( _, index ) {
    setState( { ...state, index } )
  }

  return(
    <>
      { state.data.length !== 0 ? (
        <Chart className="pie" width={ width } height={ height }>
          <RPie
            data={ state.data }
            dataKey="value"
            total={ state.total }
            length={ state.length }
            isAnimationActive={ false }
            activeIndex={ state.index || 0 }
            activeShape={ CustomActive }
            cx={ width * 0.21 }
            cy={ height * 0.5 }
            innerRadius={ 56 }
            outerRadius={ 84 }
            onMouseEnter={ onPieEnter }
          >
            {
              state.data.map( ( entry, index ) => {
                return ( <Cell key={ `cell-${ index }` } fill={ entry.color } stroke={ entry.color } /> )
              } )
            }

            <LabelList
              dataKey={ ( x ) => x.payload }
              content={ CustomLabel }
              total={ state.total }
              length={ state.length }
            />

          </RPie>
        </Chart>
      ) : <Empty /> }
    </>
  )
}

export default Pie
