import { useState, useEffect } from 'react'
import http from '../http'

const Top = ( {
  selected = { label: '' }
} ) => {
  const [ top, setTop ] = useState( [] )

  useEffect( () => {
    http
      .get( '/api/link/group', { params: {
        __field: 'domain',
        ...selected.params
      } } )
      .then( ( { data } ) => {
        setTop( data )
      } )
  }, [ selected.params ] )

  return <>
    <div className="vs gap align-center">
      <div className="one">
        <h1 className="f3">top { selected.label } domains</h1>
      </div>

      <div>
        <button className="h5 bg-primary color-white padding p2 p-sides border radius">view all</button>
      </div>
    </div>

    <div className="one">
      <table>
        <tbody>
          { top.map( ( item, index ) => <tr
            key={ index }
          >
            <td className="h4">{ item.key }</td>
          </tr> ) }
        </tbody>
      </table>
    </div>
  </>
}

export default Top
