import { useState } from 'react'
import Fetch from '../components/Fetch'
import Top from '../components/Top'

import { FiLink2 } from 'react-icons/fi'
import { BsBullseye, BsTrash, BsBell, BsEnvelope } from 'react-icons/bs'

const statusList = [
  {
    endpoint: '/api/link/count',
    label: 'located'
  },
  {
    endpoint: '/api/link/count',
    label: 'deleted',
    params: {
      status: 'deleted'
    }
  },
  {
    endpoint: '/api/link/count',
    label: 'notified',
    params: {
      status: 'notified'
    }
  },
  {
    endpoint: '/api/link/count',
    label: 'pending',
    params: {
      status: 'verified'
    }
  }
]

const Links = () => {
  const [ selected, setSelected ] = useState()

  const handleSelectedCard = ( status ) => {
    setSelected( status )
  }

  return <section className="hs gap g2">
    { /* LINKS */ }
    <div className="vs gap align-center">
      <div className="f3-8">
        <FiLink2 />
      </div>

      <h1 className="f2-8">
        links
      </h1>
    </div>

    <div className="vs gap g3">
      <div className="one hs gap g3">
        <div className="one vs wrap gap g3">
          <div
            className="one hs gap g2 space-between border radius shadow padding p3"
            onClick={ () => handleSelectedCard( statusList[ 0 ] ) }
          >
            <Fetch
              { ...statusList[ 0 ] }
            >
              { ( data ) => <div>
                <h1 className="f5">
                  { data.count }
                </h1>

                <h3 className="f2-1">
                  { statusList[ 0 ].label }
                </h3>
              </div> }
            </Fetch>

            <div className="f6 color-light-blue">
              <BsBullseye />
            </div>
          </div>

          <div
            className="one hs gap g2 space-between border radius shadow padding p3"
            onClick={ () => handleSelectedCard( statusList[ 1 ] ) }
          >
            <Fetch
              { ...statusList[ 1 ] }
            >
              { ( data ) => <div>
                <h1 className="f5">
                  { data.count }
                </h1>

                <h3 className="f2-1">
                  { statusList[ 1 ].label }
                </h3>
              </div> }
            </Fetch>

            <div className="f6 color-red">
              <BsTrash />
            </div>
          </div>

        </div>

        <div className="one vs wrap gap g3">
          <div
            className="one hs gap g2 space-between border radius shadow padding p3"
            onClick={ () => handleSelectedCard( statusList[ 2 ] ) }
          >
            <Fetch
              { ...statusList[ 2 ] }
            >
              { ( data ) => <div>
                <h1 className="f5">
                  { data.count }
                </h1>

                <h3 className="f2-1">
                  { statusList[ 2 ].label }
                </h3>
              </div> }
            </Fetch>

            <div className="f6 color-yellow">
              <BsBell />
            </div>
          </div>

          <div
            className="one hs gap g2 space-between border radius shadow padding p3"
            onClick={ () => handleSelectedCard( statusList[ 3 ] ) }
          >
            <Fetch
              { ...statusList[ 3 ] }
            >
              { ( data ) => <div>
                <h1 className="f5">
                  { data.count }
                </h1>

                <h3 className="f2-1">
                  { statusList[ 3 ].label }
                </h3>
              </div> }
            </Fetch>

            <div className="f6 color-cyan">
              <BsEnvelope />
            </div>
          </div>
        </div>
      </div>

      <div className="one hs gap g2 border radius shadow padding p3">
        <Top selected={ selected } />
      </div>
    </div>
    { /* END LINKS */ }
  </section>
}

export default Links
