import { useState, useEffect } from 'react'
import http from '../http'
import { hashIt } from '../fn'

const Fetch = ( {
  endpoint,
  method = 'GET',
  params = {},
  body = {},
  children
} ) => {
  const [ hash, updateHash ] = useState()
  const [ data, setData ] = useState()

  useEffect( () => {
    if ( ! endpoint ) return

    const newHash = hashIt( { endpoint, params, body } )

    if ( newHash === hash ) return

    updateHash( newHash )

    http( {
      url: endpoint,
      method,
      params,
      data: body
    } )
      .then( ( { data: result } ) => {
        setData( result )
      } )
  }, [ endpoint, method, params, body, hash ] )

  if ( ! data ) return null

  return children( data )
}

export default Fetch
