import Fetch from '../components/Fetch'
import PieChart from '../components/Pie'

import { TfiTarget } from 'react-icons/tfi'

const Locations = () => {

  return <section className="hs gap g2">
    <div className="vs gap align-center">
      <div className="f3-8">
        <TfiTarget />
      </div>

      <h1 className="f2-8">
        locations
      </h1>
    </div>

    <div className="vs gap g2 wrap">
      <div className="one hs border radius shadow padding p3">
        <div className="vs justify-center">
          <h1 className="f3">status</h1>
        </div>

        <Fetch
          endpoint="/api/link/group"
          params={ {
            __field: 'status'
          } }
        >
          { ( data ) => <PieChart
            data={ data }
          /> }
        </Fetch>
      </div>

      <div className="one hs border radius shadow padding p3">
        <div className="vs justify-center">
          <h1 className="f3">platform</h1>
        </div>

        <Fetch
          endpoint="/api/link/group"
          params={ {
            __field: 'platform'
          } }
        >
          { ( data ) => <PieChart
            data={ data }
          /> }
        </Fetch>
      </div>

      <div className="one hs border radius shadow padding p3">
        <div className="vs justify-center">
          <h1 className="f3">type</h1>
        </div>

        <Fetch
          endpoint="/api/link/group"
          params={ {
            __field: 'type'
          } }
        >
          { ( data ) => <PieChart
            data={ data }
          /> }
        </Fetch>
      </div>

      <div className="one hs border radius shadow padding p3">
        <div className="vs justify-center">
          <h1 className="f3">site type</h1>
        </div>

        <Fetch
          endpoint="/api/link/group"
          params={ {
            __field: 'siteType'
          } }
        >
          { ( data ) => <PieChart
            data={ data }
          /> }
        </Fetch>
      </div>

    </div>

  </section>
}

export default Locations
