import { useNavigate } from 'react-router-dom'
import Links from './sections/Links'
import Products from './sections/Products'
import Locations from './sections/Locations'

import './style/index.sass'
import logo from './logo.svg'

import { MdOutlineScreenSearchDesktop, MdWeb, MdOutlineLocalGroceryStore } from 'react-icons/md'
import { RiSignalTowerFill } from 'react-icons/ri'
import { TbSocial } from 'react-icons/tb'
import { PiSignOut } from 'react-icons/pi'
import { TiThMenuOutline } from 'react-icons/ti'
import { FiFilter, FiBarChart2 } from 'react-icons/fi'
import { HiOutlineDocumentReport } from 'react-icons/hi'

const AsideMenuLink = ( { Icon, label } ) => {

  return <li className="h6 vs gap align-center">
    <div className="w5 h5 f3-7 vs align-center justify-center">
      <Icon />
    </div>

    <span className="f2-1 fw600">
      { label }
    </span>
  </li>

}

const AsideMenu = () => {

  return <aside className="w42">
    <nav>
      <ul className="hs margin ml6">
        <AsideMenuLink
          Icon={ MdOutlineScreenSearchDesktop }
          label="search engine"
        />

        <AsideMenuLink
          Icon={ RiSignalTowerFill }
          label="streaming platforms"
        />

        <AsideMenuLink
          Icon={ MdWeb }
          label="website & cyberlockers"
        />

        <AsideMenuLink
          Icon={ TbSocial }
          label="social media"
        />

        <AsideMenuLink
          Icon={ MdOutlineLocalGroceryStore }
          label="e-commerce"
        />

      </ul>
    </nav>
  </aside>
}

const App = () => {
  const navigate = useNavigate()

  const signOut = () => {
    localStorage.clear()
    navigate( '/auth/login' )
  }

  return <main className="one hs bg-light">
    <header className="vs h10">
      <aside className="w42 vs gap g3 padding pl6 align-center">
        <button className="w5 h5 hs align-center justify-center border radius">
          <TiThMenuOutline />
        </button>

        <img src={ logo } alt="logo" className="h6" />
      </aside>

      <main className="one vs flex-end align-center">
        <div>
          <button
            onClick={ signOut }
            className="w9 h6 hs align-center justify-center f4 bg-none"
          >
            <PiSignOut />
          </button>
        </div>
      </main>
    </header>

    <section className="one vs overflow-y">
      <AsideMenu />

      <main className="one hs gap g6 bg-white border radius padding p6 overflow-y">

        <Products />

        <Links />

        <Locations />

      </main>

      <aside className="w9 hs align-center">
        <nav>
          <ul className="hs gap g3">
            <li className="f4">
              <FiFilter />
            </li>

            <li className="f4">
              <FiBarChart2 />
            </li>

            <li className="f4">
              <HiOutlineDocumentReport />
            </li>
          </ul>
        </nav>
      </aside>
    </section>
  </main>
}

export default App
